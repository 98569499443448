import {SystemType} from "Util/SystemType.js";
import {core_config_version} from "itchi_core_config.js";


const config = {
    targetFlags: [
        SystemType.Default,
        SystemType.Custom, 
        // SystemType.ERP //No need to use preload if not ERP (e.g. school project)
        // SystemType.POS
    ],
    app_name: "KidCare Dash",
    version: core_config_version,
    language: "zh_Hant",
    receipt_logo:"",
    // language: "en",
    // serverBaseUrl: "http://localhost:1111/"
    // serverBaseUrl: "https://itchitechdev.com:30001/"
    serverBaseUrl: "https://api.kidcarehk.com/"

    
    // serverBaseUrl: "http://localhost:5001/emaapp-d0ab4/us-central1/app/"
    // serverBaseUrl: "https://us-central1-emaapp-d0ab4.cloudfunctions.net/app/"
};
export default config;
