// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
var firebase = require("firebase/app");

// Add the Firebase products that you want to use
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

const firebaseConfig = 
{
    apiKey: "AIzaSyAj-9qke_kLyNfJsBQZgPn44O_MRvwozK8",
    authDomain: "emaapp-d0ab4.firebaseapp.com",
    projectId: "emaapp-d0ab4",
    storageBucket: "emaapp-d0ab4.appspot.com",
    messagingSenderId: "848495294960",
    appId: "1:848495294960:web:2a064a83616e4785388535",
    measurementId: "G-QC3VGEG3Y4"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);


export default firebase;